import * as queryString from 'query-string';
import Url from 'url-parse';
/**
 * common-util
 *
 * @author fukui
 */
export const getQueryParams = function (name: string): string | string[] | null {
  return getSearchParams()[name];
};

export const getSearchParams = () => {
  let { hash } = window.location;
  const hashIndex = hash.indexOf('?');

  if (hashIndex > -1) {
    hash = hash.substring(hashIndex);
  }
  return Object.assign(queryString.parse(window.location.search), queryString.parse(hash));
};

export const getBrowserInfos = () => {
  const UA = window.navigator.userAgent.toLowerCase();
  const isIOS = /iphone|ipad|ipod|ios/.test(UA);
  const isAndroid = UA.indexOf('android') > 0;
  const isWechat = UA.indexOf('micromessenger') !== -1;
  const isQq = UA.indexOf('qq') > -1;
  const isMobile = !!(
    UA.match(/android/i) ||
    UA.match(/webos/i) ||
    UA.match(/iphone/i) ||
    UA.match(/ipad/i) ||
    UA.match(/ipod/i) ||
    UA.match(/blackberry/i) ||
    UA.match(/windows phone/i)
  );
  return {
    UA,
    isAndroid,
    isIOS,
    isWechat,
    isQq,
    isMobile,
  };
};

export const preventTouchMove = () => {
  document.body.addEventListener(
    'touchmove',
    (event) => {
      event.preventDefault();
    },
    { passive: false },
  );
};

export const getCurrentRootUrl = () => {
  return `${window.location.origin}${window.location.pathname ? window.location.pathname : '/'}${
    window.location.search
  }#/`;
};

export const setPageTitle = (title: string) => {
  document.title = title;
  const userAgent = navigator.userAgent.toLowerCase();

  // 修复ios下，不能触发修改页面标题的问题
  if (/iphone|ipad|ipod/.test(userAgent)) {
    const iframe = document.createElement('iframe');
    iframe.src = '/favicon.ico';
    iframe.style.visibility = 'hidden';
    iframe.style.width = '1px';
    iframe.style.height = '1px';
    iframe.onload = function () {
      setTimeout(() => {
        document.body.removeChild(iframe);
      }, 0);
    };
    document.body.appendChild(iframe);
  }
};

export const deepCopy = <T>(obj: T): T => {
  return JSON.parse(JSON.stringify(obj));
};

export const reload = () => {
  if (getBrowserInfos().isIOS) return location.reload();

  const res = new Url(location.href);
  const { hash, query = '' } = res;
  const queryIndex = query.indexOf('?');
  let queryStr = '';

  if (queryIndex > -1) {
    queryStr = query.substring(queryIndex + 1);
  }

  const queryObject = queryString.parse(queryStr);
  // @ts-ignore
  queryObject.__refresh__ = Date.now();
  const finalUrl = `${res.origin}${res.pathname}?${queryString.stringify(queryObject)}${hash}`;
  location.href = finalUrl;
};

export const isSupportWebp = () => {
  try {
    return document.createElement('canvas').toDataURL('image/webp').indexOf('data:image/webp') === 0;
  } catch (err) {
    return false;
  }
};

export const isWifi = (debug?: boolean) => {
  if (debug) return true;
  try {
    let wifi = true;
    const ua = window.navigator.userAgent;
    // @ts-ignore
    const con = window.navigator.connection;
    // 如果是微信
    if (/MicroMessenger/.test(ua)) {
      if (ua.indexOf('WIFI') >= 0) {
        return true;
      } else {
        wifi = false;
      }
      // 如果支持navigator.connection
    } else if (con) {
      const network = con.type;
      if (network !== 'wifi' && network !== 'unknown') {
        wifi = false;
      }
    }
    return wifi;
  } catch (e) {
    return false;
  }
};

export const getWebHost = (isProd: boolean) => {
  if (!isProd) return 'https://web-test.yigoweb.com';
  if (['web.yigoweb.com', 'web.yigolive.com', 'web.arhbo.chat'].includes(location.host)) {
    return `https://${location.host}`;
  }
  return 'https://web.yigoweb.com';
};

export const getWebHostProdRelativePath = (isDev: boolean) => {
  if (location.host.includes('web-test') || isDev) return 'https://web.yigoweb.com';
  if (location.host.includes('yigolive.com')) return 'https://web.yigolive.com';
  if (location.host.includes('arhbo.chat')) return 'https://web.arhbo.chat';
  if (location.host.includes('yichat.live')) return 'https://web.yichat.live';
  return '';
};

export const getYigoI18nHost = (isDev: boolean) => {
  if (location.host.includes('web-test') || isDev) return 'https://web.yigoweb.com';
  if (location.host.includes('yigolive.com')) return 'https://web.yigolive.com';
  if (location.host.includes('arhbo.chat')) return 'https://web.arhbo.chat';
  return '';
};

// compareVersion('1.1.0', '1.0.0') // 1
// compareVersion('1.1.0', '1.1.0') // 0
// compareVersion('1.0.0', '1.1.0') // -1
export const compareVersion = (v1: string, v2: string) => {
  if (v1 === v2) return 0;

  const v1Arr = v1.split('.');
  const v2Arr = v2.split('.');
  const len = Math.max(v1Arr.length, v2Arr.length);

  while (v1Arr.length < len) {
    v1Arr.push('0');
  }
  while (v2Arr.length < len) {
    v2Arr.push('0');
  }

  for (let i = 0; i < len; i++) {
    const num1 = parseInt(v1Arr[i]);
    const num2 = parseInt(v2Arr[i]);

    if (num1 > num2) {
      return 1;
    } else if (num1 < num2) {
      return -1;
    }
  }

  return 0;
};
